<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form>

        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="4"
            >

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                hint="Make sure it's at least 8 characters."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                :rules="[required,passwordValidator]"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
                :rules="[required,confirmedValidator(newPassword,cPassword)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="submitForm"
          >
            Save changes
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import {
  required,
  passwordValidator,
  confirmedValidator,
} from '@core/utils/validation'

export default {
  props: {

  },
  setup(props,{emit}) {
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const newPassword = ref('')
    const cPassword = ref('')

    const submitForm = () => {
      emit('update-data',{password:newPassword.value})
    }

    return {
      isNewPasswordVisible,
      isCPasswordVisible,
      newPassword,
      cPassword,
      submitForm,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      required,
      passwordValidator,
      confirmedValidator
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
