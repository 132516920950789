<template>
  <v-card id="account-setting-card">

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      ref="refTab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <user-edit-info :user-data="userData.info" @update-data="updateData"></user-edit-info>
      </v-tab-item>
      <v-tab-item>
        <user-security @update-data="updateData"></user-security>
      </v-tab-item>
      <v-tab-item>
        <user-profile-calendar @update-data="updateData"></user-profile-calendar>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLocker,
  mdiCalendar,
  mdiArrowLeft
} from '@mdi/js'
import store from '@/store'
import router from '@/router'
import { onUnmounted, ref } from '@vue/composition-api'
import userStoreModule from '@/views/apps/user/userStoreModule'

import UserEditInfo from '@/views/apps/user/user-edit/UserEditInfo.vue'
import UserSecurity from './UserSecurity.vue'
import UserProfileCalendar from './UserProfileCalendar.vue'

export default {
  components: {
    UserEditInfo,
    UserSecurity,
    UserProfileCalendar
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const tab = ref(null)
    const refTab = ref(null)
    const userData = ref({
      info: {},
      security: {},
      settings: {}
    })

    // tabs
    const tabs = [
      { title: 'Info', icon: mdiAccountOutline },
      { title: 'Security', icon: mdiLockOpenOutline },
      { title: 'Calendar', icon: mdiCalendar },
    ]

    store
      .dispatch('app-user/fetchMe')
      .then(response => {

        userData.value.info = {
          username: response.data.user.username,
          email: response.data.user.email,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          avatar: 3
        }

      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = {}
        }
      })

    const updateData = (data) => {

      store.dispatch('app-user/updateMe', {userData: data}).then((response) => {
        store.dispatch("addNotification",{message:"Settings were saved.",type: "success"});
        localStorage.setItem('userData', JSON.stringify(response.data.user))
      }).catch((error) => {
        store.dispatch("addNotification",{message:error.response.data.data,type: "error"});
      })

    }

    return {
      userData,
      refTab,
      tab,
      tabs,
      updateData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiLocker,
        mdiCalendar,
        mdiArrowLeft
      },
    }
  },
}
</script>
