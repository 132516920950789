<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="">
      <v-card-text class="pt-5">

        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-for="color in colors"
              v-model="color.label_color" mask="!#XXXXXXXX"
              outlined
              readonly
              dense
              hide-details="auto"
              class="mb-6"
              :label="color.name">
    					<template v-slot:append>
    						<v-menu v-model="color.menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
    								<div :style="swatchStyle(color)" v-on="on" />
    							</template>
    							<v-card>
    								<v-card-text class="pa-0">
    									<v-color-picker v-model="color.label_color" flat />
    								</v-card-text>
    							</v-card>
    						</v-menu>
    					</template>
    				</v-text-field>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          @click="submitForm"
        >
          Save changes
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
          @click.prevent="resetForm"
        >
          Cancel
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from '@/views/apps/calendar/calendarStoreModule'
import { hexToRgb } from '@core/utils'

export default {
  setup(props,{emit}) {

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const swatchStyle = (color) => {
      return {
        backgroundColor: color.label_color,
        cursor: 'pointer',
        height: '25px',
        width: '25px',
        borderRadius: color.menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }

    const colors = ref([]);

    store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchSports`)
        .then(response => {
          colors.value = response.data

          colors.value.map(sport => {
            sport.menu = false

          })
        })
        .catch(error => {
          console.error(error)
          console.error(error.response)
        })

    const submitForm = () => {
      emit('update-data',{colors:colors.value})
    }

    return {
      colors,
      swatchStyle,
      submitForm
    }
  },
}
</script>
